
import { BButton, BJumbotron } from 'bootstrap-vue';
import { Component, Vue } from 'vue-property-decorator';
import { Hooper, Slide } from 'hooper';
import { TITLES } from '@thxnetwork/public/utils/constants';
import { DASHBOARD_URL } from '../config/secrets';

@Component({
    components: {
        'b-jumbotron': BJumbotron,
        'b-button': BButton,
        'hooper': Hooper,
        'slide': Slide,
    },
})
export default class BaseMetrics extends Vue {
    TITLES = TITLES;
    dashboardUrl = DASHBOARD_URL;
}
